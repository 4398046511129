import React from 'react'
import UserClass from './UserClass';
const About = () => {
  return (
    <>
    <UserClass name={"Lalit"}/>
    <div>About</div>
    </>
    
  )
}

export default About;