import React from 'react';

const Shimmer=()=>{
    return (
<div className='shimmer-container' style={{width:"100%",height:"450px",background:"orange"}}>
    
<div className='card' style={{background:"orange"}}>
    <div> </div>
     </div>
    
</div>
    )

}
export default Shimmer;
